<template>
  <section class="wrapper-login">
    <Card>
      <template v-slot:card-header>
        <Logo
          :path="logo"
          :className="'d-flex justify-content-center'"
          :width="'100'"
        ></Logo>
      </template>
      <template v-slot:card-body>
        <h2 class="text-center">Recovery</h2>
        <p>
          Type the secret recovery code that you have to previously have save in
          a secure place.
        </p>
        <form class="login-form" @submit.prevent="submitRecoveryCode">
          <div class="form-group">
            <label for="code">Code</label>
            <input
              class="form-input"
              type="number"
              placeholder="Secret recovery code"
              id="code"
              v-model="code"
              required
            />
          </div>
          <div v-if="showErrorMessage" class="alert alert-danger">
            The provided security code is not valid. Please try again or use the
            authentication app to login
          </div>
          <button class="btn btn-primary" type="submit">Verify</button>
        </form>
        <div class="alert">
          <p>Do not want to use a recovery code?</p>
          <ul>
            <li>
              <button
                class="btn btn-link"
                @click="this.$router.push({ name: 'TOTP Login' })"
              >
                Login using the TOTP authorization app
              </button>
            </li>
          </ul>
        </div>
      </template>
    </Card>
  </section>
</template>
<script>
import Logo from "@/components/auth/Logo";
import Card from "@/components/auth/Card";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    Logo,
    Card,
  },

  data() {
    return {
      code: null,
      logo: require("@/assets/logo.png"),
      showErrorMessage: false,
    };
  },

  computed: {
    ...mapGetters("user", ["getUserIsLogged"]),
  },

  methods: {
    ...mapActions("user", ["verifyRecoveryCode", "getUserData"]),
    submitRecoveryCode() {
      this.verifyRecoveryCode(this.code).then((response) => {
        if (response) {
          this.$router.push({
            name: "Home",
          });
        } else {
          this.showErrorMessage = true;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.wrapper-login {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  height: 100vh;
  background: $background-login;

  .login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 2rem;

    .form-group {
      margin-bottom: 1rem;
      display: flex;
      flex-direction: column;

      label {
        text-align: start;
        margin-bottom: 3px;
        font-size: 10pt;
      }
      input {
        margin-bottom: 0.5rem;
      }

      a {
        width: 100%;
        display: flex;
        justify-content: flex-end;

        &.forgot {
          font-size: 10pt;
        }
      }
    }
    button {
      background-color: rgba(218, 114, 105, 1);
      border: none;
    }
  }

  .auth__social-networks {
    margin-bottom: 0.5rem;
    display: grid;
    gap: 10px;

    .subtitle {
      margin-top: 0;
      text-align: center;
    }
  }
}
</style>
